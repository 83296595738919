<template>
  <YLayoutBodyMenu aside-width="8rem" :menu="[
      {path:'/user/visitor/mini',title:'小程序访客'},
      {path:'/user/visitor/official',title:'公众号访客'},
      {path:'/user/visitor/official-sub',title:'公众号借权访客'},
  ]">
    <router-view/>
  </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>